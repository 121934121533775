<template>
  <div class="bg-white border rounded-bl rounded-br flex border-primary-300 mx-4 mb-4 py-2 px-4 right-0 bottom-0 left-0 absolute items-center justify-between">
    <div class="text-sm text-primary-700 w-72">
        {{config.message}}
        <a v-if="config.showHereLink" @click="hereLink" class="cursor-pointer text-blue hover:text-blue hover:underline">here</a>
    </div>
    <div class="btn-container">
      <Button
        variant="secondary"
        @click="closePopover()"
      >
        Cancel
      </Button>

      <Button
        class="ml-2.5"
        v-for="(actionBtn, i) in config.actionBtns"
        :key="i"
        v-show="!actionBtn.isHidden"
        :variant="actionBtn.isDisabled ? 'disabled' : 'primary'"
        @click="actionBtnClicked(actionBtn)"
      >
        {{actionBtn.label}}
      </Button>
    </div>
  </div>
</template>

<script>
import store from '@/services/store'
import Button from '@/components/base/buttons/Button.vue'

export default {
  name: 'AddFileFooter',
  components: {
    Button
  },
  props: {
    config: {}
  },
  methods: {
    hereLink () {
      store.dispatch('modal/setShowCredits', true)
    },
    closePopover () {
      store.dispatch('dialogs/closeModal')
    },
    actionBtnClicked (actionBtn) {
      this.$emit('actionBtnClick', actionBtn)
    }
  }
}
</script>

<style lang="scss" scoped></style>
