<template>
  <div class="w-full">
    <!-- Search section -->
    <div
      v-if="uploadStatus === 'enter'"
    >
      <!-- Search Box -->
      <div class="border-b border-primary-300 relative">
        <input
          type="text"
          placeholder="Search Music"
          @focus="isFocused=true"
          @focusout="isFocused=false"
          v-model="searchQuery"
          @keyup.enter="getResults()"
          class="border-none rounded-tr bg-primary-100 text-sm text-primary w-full py-3 pr-10 pl-3 placeholder-primary-500 block focus:ring-0"
        />
        <div
          class="cursor-pointer flex pr-3 inset-y-0 right-0 text-primary-700 absolute items-center hover:text-primary"
          @click="getResults()"
        >
          <SearchIcon classname="h-6 w-6" />
        </div>
      </div>

      <!-- Pre-defined filters -->
      <div class="flex mx-5 mt-4 items-center">
        <span class="font-bold text-xs text-primary-700">
          By Mood:
        </span>

        <div class="flex items-center">
          <span
            v-for="(filter, i) in filters"
            :key="i"
            @click="changeSearch(filter)"
            class="rounded cursor-pointer text-xs ml-2 py-1.25 px-1.5 text-primary-700 hover:bg-primary-200"
            :class="selectedFilter === filter ? 'bg-primary-200' : ''"
          >
            {{ filter }}
          </span>
        </div>
      </div>

      <!-- Search Loader -->
      <div
        class="flex flex-col mx-5 mt-4 text-sm text-primary leading-6 resultsContainer items-center justify-center"
        v-if="showSearchLoader && searchInitiated && !showNoResultsError"
      >
        <SearchIcon class="h-10 w-10"/>
        <div>
          Searching for
        </div>
        <div class="font-bold">
          '{{searchQuery}}' sounds...
        </div>
      </div>

      <!-- Search Results -->
      <ul
        v-if="soundResults && soundResults.length && !showNoResultsError"
        class="mx-5 mt-4 grid gap-x-4 gap-y-0 grid-cols-2 resultsContainer overflow-y-auto overflow-x-hidden"
      >
        <li
          v-for="(item, i) in soundResults"
          :key="i"
          @click="selectAudio(item)"
          class="border-b cursor-pointer flex border-primary-200 py-4.5 px-1.5 col-span-1 items-center justify-between group"
          :class="[selectedAudio.id === item.id ? 'bg-primary-200' : 'hover:bg-primary-100']"
        >
          <div class="resultItem">
            <div
              class="font-semibold text-sm text-primary-900 whitespace-nowrap overflow-ellipsis overflow-hidden"
              :class="{'font-bold': selectedAudio.id === item.id}"
            >
              {{ item.name }}
            </div>
            <div
              class="text-xs duration text-primary-600"
              :class="{'font-bold': selectedAudio.id === item.id}"
            >
              {{item.time}}
            </div>
          </div>

          <div class="flex items-center">
            <div class="text-2xl" v-if="item.id === playedItem.id">
              <div
                v-if="!status || status==='pause'"
                class="cursor-pointer text-primary hover:text-red"
                @click="playAudio(item)"
              >
                <MusicPlay class="h-6 w-6" />
              </div>
              <div
                v-else-if="status==='seeking'"
                class="text-red"
              >
                <CircularLoader class="h-6 w-6" />
              </div>
              <div
                v-else-if="status==='playing'"
                class="cursor-pointer text-red hover:text-primary"
                @click="pauseAudio"
              >
                <MusicPause class="h-6 w-6" />
              </div>
            </div>
            <div
              v-else
              @click="playAudio(item)"
              class="cursor-pointer text-primary hover:text-red"
            >
              <MusicPlay class="h-6 w-6" />
            </div>

            <div
              v-tooltip.top="'Search similar sounds'"
              class="cursor-pointer text-primary ml-1 hover:text-red"
              @click="getSimilarSounds(item)"
            >
              <SimilarIcon class="h-5 w-5" />
            </div>
          </div>

        </li>

        <audio
          @playing="playing"
          @pause="pause"
          @ended="ended"
          @seeking="seeking"
          @waiting="waiting"
          ref="audio"
        />
      </ul>

      <!-- No results found error -->
      <div
        class="flex flex-col mx-5 mt-4 text-sm text-primary-600 leading-6 resultsContainer items-center justify-center"
        v-if="showNoResultsError"
      >
        <span>No results found</span>
      </div>
    </div>

    <!-- Progress Bar -->
    <div v-else-if="uploadStatus === 'inProgress'" class="in-progress-section">
      <div class="text-sm mb-4">
        Adding the file...
      </div>

      <div class="progress-outer">
        <div
          class="progress-inner"
          :style="'width:'+addFileProgressBarPercent+'%;'"
          ref="addFileProgressBar"
        />
      </div>

      <span class="font-semibold mt-6 text-sm">
        {{addFileProgressBarPercent}} %
      </span>
    </div>

    <!-- Upload completed -->
    <div v-else-if="uploadStatus === 'complete'" class="complete-section">
      <div class="text-green">
        <FileAdded class="h-10 w-10" />
      </div>
      <div v-if="fileTitle" class="mt-3 text-sm text-primary">
        {{ fileTitle }}
      </div>
    </div>

    <!-- Upload error -->
    <div v-else-if="uploadStatus === 'error'" class="error-section">
      <FileAlert class="h-10 text-primary w-10" />
      <div class="mt-2.5 text-sm text-primary text-center">
        Oops! Looks like we weren’t able to import your file.
        <br/>
        Would you like to try again or select another track?
      </div>

      <Button
        class="mt-3"
        @click="tryAgain()"
      >
        Yes, Try Again
      </Button>
    </div>

    <AddFilesFooter :config="footerConfig" @actionBtnClick="actionBtnClick($event)"></AddFilesFooter>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SoundsService from '@/services/api/Sounds'
import store from '@/services/store'

import Button from '@/components/base/buttons/Button.vue'
import SearchIcon from '@/components/base/icons/Search.vue'
import CircularLoader from '@/components/base/icons/CircularLoader.vue'
import MusicPlay from '@/components/base/icons/MusicPlay.vue'
import MusicPause from '@/components/base/icons/MusicPause.vue'
import SimilarIcon from '@/components/base/icons/Similar.vue'
import FileAdded from '@/components/base/icons/FileAdded.vue'
import FileAlert from '@/components/base/icons/FileAlert.vue'
import AddFilesFooter from '../../AddFileFooter/AddFileFooter'

export default {
  name: 'MusicLibrary',
  components: {
    AddFilesFooter,
    CircularLoader,
    MusicPlay,
    MusicPause,
    SimilarIcon,
    SearchIcon,
    FileAlert,
    FileAdded,
    Button
  },
  data () {
    return {
      timerId: '',
      status: '',
      filters: [],
      soundResults: [],
      selectedAudio: {},
      selectedFilter: '',
      playedItem: {},
      isFocused: false,
      showSearchLoader: false,
      audio: undefined,
      searchQuery: '',
      searchInitiated: false,
      arrows: {
        left: false,
        right: false
      },
      showNoResultsError: false,
      uploadStatus: 'enter',
      addFileProgressBarPercent: 10,
      fileTitle: '',
      footerConfig: {
        message: 'All sounds are from Freesound.org and you can use them commercially for your projects. More details ',
        showHereLink: true,
        actionBtns: {
          'next': {
            label: 'Add File',
            type: 'upload',
            isDisabled: true,
            isHidden: false
          },
          'addFile': {
            label: 'Add file',
            type: 'addFile',
            isDisabled: true,
            isHidden: true
          }
        }
      }
    }
  },
  created () {
    (this.filters = [
      'Happy',
      'Playful',
      'Relaxing',
      'Mystery',
      'Sad',
      'Ambient'
    ])
  },
  destroyed () {
    this.pauseAudio()
    this.footerConfig.actionBtns['next'].isDisabled = true
  },
  computed: {
    ...mapGetters({
      modalProps: 'dialogs/modalProps'
    }),
    isReplacing: function() {
      return this.modalProps.isReplacing
    },
    browserIs () {
      return store.state.app.browserIs
    },
    user () {
      return store.state.app.user
    }
  },
  methods: {
    ...mapActions({
      closeModal: 'dialogs/closeModal'
    }),
    tryAgain: function() {
      this.uploadStatus = 'enter'
      this.footerConfig = {
        message: 'All sounds are from Freesound.org and you can use them commercially for your projects. More details ',
        showHereLink: true,
        actionBtns: {
          'next': {
            label: 'Add File',
            type: 'upload',
            isDisabled: true,
            isHidden: false
          },
          'addFile': {
            label: 'Add file',
            type: 'addFile',
            isDisabled: true,
            isHidden: true
          }
        }
      }
    },
    changeOption (ev) {
      this.selectedMenu = ev.value
    },
    changeSearch (filter) {
      this.selectedFilter = filter
      this.searchQuery = filter
      this.getResults()
    },
    getResults () {
      this.pauseAudio()
      this.showNoResultsError = false
      this.soundResults = []
      this.showSearchLoader = true
      this.searchInitiated = true
      this.footerConfig.actionBtns['next'].isDisabled = true

      // Reset selected filter
      if (this.selectedFilter !== this.searchQuery) {
        this.selectedFilter = ''
      }

      SoundsService.getSounds(this.searchQuery).then(
        (res) => {
          this.footerConfig.actionBtns['next'].isDisabled = true

          if (res.data && res.data.results && res.data.results.length) {
            this.soundResults = res.data.results
            this.showSearchLoader = false

            this.soundResults = this.soundResults.map(result => {
              result.time = this.convertToHHMMSS(result.duration)
              return result
            })
          } else {
            this.showNoResultsError = true
          }
        },
        (err) => {
          console.log('err', err)
          this.showNoResultsError = true
        }
      )
    },
    playAudio (audioItem) {
      this.pauseAudio()
      if (!audioItem.previews) {
        return
      }
      // console.log(audioItem.previews)
      let audioURL
      if (this.browserIs === 'chrome' && audioItem.previews['preview-lq-ogg']) {
        audioURL = audioItem.previews['preview-lq-ogg']
      } else if (this.browserIs === 'chrome' && audioItem.previews['preview-hq-ogg']) {
        audioURL = audioItem.previews['preview-hq-ogg']
      } else if (audioItem.previews['preview-lq-mp3']) {
        audioURL = audioItem.previews['preview-lq-mp3']
      } else if (audioItem.previews['preview-hq-mp3']) {
        audioURL = audioItem.previews['preview-hq-mp3']
      }

      if (audioURL) {
        let vm = this
        this.playedItem = audioItem
        vm.$refs.audio.src = audioURL
        vm.$refs.audio.play()
      }
    },
    pauseAudio () {
      if (this.$refs.audio) {
        this.$refs.audio.pause()
      }
    },
    updateProgress () {
      let vm = this
      if (vm.timerId !== '') {
        clearInterval(vm.timerId)
      }
      this.addFileProgressBarPercent = 0
      this.timerId = setInterval(() => {
        this.addFileProgressBarPercent += 10
        if (this.addFileProgressBarPercent === 100) {
          clearInterval(vm.timerId)
          // this.uploadStatus = 'complete'
        }
      }, 4000)
    },
    getSimilarSounds (item) {
      this.pauseAudio()
      this.selectedFilter = ''
      this.soundResults = []
      this.showSearchLoader = true
      this.searchInitiated = true
      this.searchQuery = item.name
      this.footerConfig.actionBtns['next'].isDisabled = true

      SoundsService.getSimilarSounds(item.id).then(
        (res) => {
          this.selectedAudio = {}
          this.footerConfig.actionBtns['next'].isDisabled = true
          this.soundResults = res.data.results
          this.showSearchLoader = false
          this.soundResults = this.soundResults.map(result => {
            result.time = this.convertToHHMMSS(result.duration)
            return result
          })
        },
        (err) => {
          console.log(err)
        }
      )
    },
    selectAudio (item) {
      this.selectedAudio = item

      this.footerConfig.actionBtns['next'].isDisabled = false
      this.fileTitle = this.selectedAudio.name
    },
    convertToHHMMSS (seconds) {
      var secNum = parseInt(seconds, 10)
      var hours = Math.floor(secNum / 3600)
      var minutes = Math.floor((secNum - (hours * 3600)) / 60)
      seconds = secNum - (hours * 3600) - (minutes * 60)

      if (hours < 10) {
        hours = '0' + hours
      }
      if (minutes < 10) {
        minutes = '0' + minutes
      }
      if (seconds < 10) {
        seconds = '0' + seconds
      }
      return (hours !== '00' ? (hours + ':') : '') + minutes + ':' + seconds
    },
    actionBtnClick (actionBtn) {
      if (actionBtn.type === 'upload') {
        this.submitFile()
        this.footerConfig.actionBtns['addFile'].isHidden = false
        this.footerConfig.actionBtns['next'].isHidden = true
        this.uploadStatus = 'inProgress'
      } else if (actionBtn.type === 'addFile') {
        store.dispatch('modal/addFile', this.isReplacing)
      }
    },
    backBtn () {
      this.uploadStatus = 'enter'
      this.fileTitle = ''
      this.footerConfig.actionBtns['next'].isHidden = false
      this.footerConfig.actionBtns['addFile'].isHidden = true
      this.footerConfig.actionBtns['addFile'].isDisabled = true
    },
    submitFile () {
      const body = {
        sound_id: this.selectedAudio.id,
        userid: this.user.uid,
        name: this.selectedAudio.name
      }
      let vm = this
      vm.updateProgress()
      SoundsService.uploadSound(body).then(
        (res) => {
          clearInterval(vm.timerId)
          vm.addFileProgressBarPercent = 100
          store.commit('modal/setSelectedFile', { type: 'library', key: res.data })
          this.uploadStatus = 'complete'

          store.dispatch('modal/addFile', this.isReplacing)
        },
        (err) => {
          console.log(err)
          this.uploadStatus = 'error'
        }
      )
    },
    setPause () {
      console.log('pausing')
      this.$refs.audio.pause()
    },
    playing () {
      console.log('playing')
      this.status = 'playing'
    },
    pause () {
      console.log('paused')
      this.status = 'pause'
    },
    ended () {
      console.log('ended')
      this.status = ''
    },
    seeking () {
      console.log('seeking')
      this.status = 'seeking'
    },
    waiting () {
      console.log('wating')
      this.status = 'seeking'
    }
  }
}
</script>

<style scoped lang="scss">
@import url("https://use.fontawesome.com/releases/v5.8.1/css/all.css");

.resultsContainer {
  min-height: 210px;
  max-height: 210px;
}

.resultItem {
  max-width: calc(100% - 64px);
}

.in-progress-section {
  min-height: 300px;
  max-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .progress-outer {
    width: 430px;
    background-color: #F3F4F6;
    height: 10px;
    border-radius: 10px;
    margin: 0 auto;
  }
  .progress-inner {
    width: 10%;
    background-color: #E2462C;
    height: 10px;
    border-radius: 10px;
    transition: 0.2s ease;
  }
}

.complete-section {
  min-height: 300px;
  max-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.error-section {
  min-height: 300px;
  max-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
