<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    :class="classname"
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5239 12.5243C12.8558 12.1924 13.394 12.1924 13.7259 12.5243L18.4009 17.1994C18.7329 17.5313 18.7329 18.0695 18.4009 18.4014C18.069 18.7334 17.5308 18.7334 17.1989 18.4014L12.5239 13.7264C12.1919 13.3945 12.1919 12.8563 12.5239 12.5243Z" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.29961 5.05156C6.9524 5.05156 5.04961 6.95435 5.04961 9.30156C5.04961 11.6488 6.9524 13.5516 9.29961 13.5516C11.6468 13.5516 13.5496 11.6488 13.5496 9.30156C13.5496 6.95435 11.6468 5.05156 9.29961 5.05156ZM3.34961 9.30156C3.34961 6.01547 6.01352 3.35156 9.29961 3.35156C12.5857 3.35156 15.2496 6.01547 15.2496 9.30156C15.2496 12.5877 12.5857 15.2516 9.29961 15.2516C6.01352 15.2516 3.34961 12.5877 3.34961 9.30156Z" />
  </svg>
</template>

<script>
export default {
  name: 'Search',
  props: {
    classname: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="css" scoped>
svg {
  fill: currentColor;
}
</style>
